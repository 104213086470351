import { useState, useEffect } from 'react';
import packageJson from '../package.json';

const CacheBuster = ({ children }) => {
    const [isLatestVersion] = useState(true);
    const [loading, setLoading] = useState(true);
    const [latestVersion, setLatestVersion] = useState(1);

    const appVersion = packageJson.version;

    const refreshCacheAndReload = () => {
        console.log('Clearing cache and hard reloading...');

        if (caches) {
            caches.keys().then(names => {
                names.forEach(name => caches.delete(name));
            });
        }

        localStorage.setItem('appVersion', appVersion);
        window.location.reload(true);
    };

    useEffect(() => {
        const localStorageVersion = localStorage.getItem('appVersion');
        if (localStorageVersion !== appVersion) {
            console.log(`New version detected - ${appVersion}. Updating application...`);
            refreshCacheAndReload();
        } else {
            console.log(`You are running the latest version - ${appVersion}.`);
            setLoading(false);
        }
        setLatestVersion(appVersion);
    }, [appVersion]);



    return children({
        loading,
        isLatestVersion,
        latestVersion,
    });
};

export default CacheBuster;