import WebFont from 'webfontloader';
import './index.css';
import './custom.scss';
import 'font-awesome/css/font-awesome.min.css';
import register from 'preact-custom-element';
import Root from './root';



import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
// import './ReactWebComponent'; // Import your web component definition


// Load all the webfonts
WebFont.load({
  google: {
    families: ['Open Sans:300,400,600,700', 'Oswald:300,400,500,600'],
  },
});

const isSafari = () => (
  CSS.supports('-webkit-backdrop-filter', 'none')
  && 'webkitAppearance' in document.documentElement.style
  && !window.chrome
);

const requestStorageAccess = () => {
  if (document.requestStorageAccess) {
    document.requestStorageAccess().then(() => {
      console.log('Storage access granted');
      // Proceed with your app logic
    }).catch(() => {
      console.log('Storage access denied');
      // Handle the denial, possibly by showing a message to the user
    });
  } else {
    console.log('Storage Access API not supported');
    // Fallback for browsers that do not support the API
  }
};

// Function to check storage access
const checkStorageAccess = () => {
  if (isSafari()) {
    requestStorageAccess();
  } else {
    console.log('Storage Access API is not required for this browser.');
  }
};

// Call the storage access check when the app is initialized
checkStorageAccess();

register(Root, 'simulator-web-component', ['simulatorToken'], { shadow: false });

// const container = document.getElementById("root");
// const root = createRoot(container);

// // render(<Root />, document.getElementById("root"));
// root.render(<Root />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
reportWebVitals();
