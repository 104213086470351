import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import loadable from '@loadable/component';
import { Loading } from './loading';
import Errors from './errors';
import './scss/main.scss';


const AsyncHome = loadable(() => import(/* webpackChunkName: "home", webpackPrefetch: true */ './containers/home/index'), {
  fallback: <Loading />,
});

function App(props) {
  const { version, simulatorToken } = props;
  return (
    <Router basename="/sim">
      <Errors>
          <AsyncHome simulatorToken={simulatorToken} version={version} />
      </Errors>
      <ToastContainer />
    </Router>
  );
}

export default App;
