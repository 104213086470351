import CacheBuster from './CacheBuster';
import App from './App';

function Root({ email, simulator_token }) {
  return (
<CacheBuster>
    {({ latestVersion, loading, isLatestVersion, refreshCacheAndReload }) => {
      if (loading) return null;
      if (!loading && !isLatestVersion) {
        refreshCacheAndReload();
      }
      return <App email={email} simulatorToken={simulator_token} version={latestVersion} />;
    }}
</CacheBuster>
);
}

export default Root;
